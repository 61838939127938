import { Runtime, Inspector } from '@observablehq/runtime';
import React, { useEffect, useRef } from 'react';
import notebook from './@d3/zoomable-sunburst';
import Page from './Page';


export default function Locus() {
  const ref = useRef();

  useEffect(() => {
    const runtime = new Runtime();
    runtime.module(notebook, (name) => {
      if (name === 'chart') {
        return new Inspector(ref.current);
      }
    });
    return () => runtime.dispose();
  }, []);

  return (
    <>
    <Page>
      <div ref={ref} />
    </Page>
    </>
  );
}