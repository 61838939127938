import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Locus from "./Locus"
import Locus2 from "./Locus2"


export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="locus" element={<Locus />} />
        <Route path="locus2" element={<Locus2 />} />
      </Routes>
    </div>
  );
}