import data from './data';

const _chart = (partition, data, d3, width, color, arc, format, radius) => {
  const root = partition(data);

  root.each(d => d.current = d);

  const svg = d3.create("svg")
    .attr("viewBox", [0, 0, width, width])
    .style("fill", "#212121");

  const g = svg.append("g")
    .attr("transform", `translate(${width / 2},${width / 2})`);

  const path = g.append("g")
    .selectAll("path")
    .data(root.descendants().slice(1))
    .join("path")
    .attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
    .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.8 : 0.4) : 0)
    .attr("pointer-events", d => arcVisible(d.current) ? "auto" : "none")
    .attr("d", d => arc(d.current));

  path.filter(d => d.children)
    .style("cursor", "pointer")
    .on("click", clicked)
    .on("hover", clicked);

  path.append("title")
    .text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(d.value)}`);

  const label = g.append("g")
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .style("user-select", "none")
    .selectAll("text")
    .data(root.descendants().slice(1))
    .join("text")
    .style("font", d => `${d.data.name.length === 2 && d.parent ? 16 : 12}px verdana`)
    .attr("dy", "0.28em")
    .attr("fill-opacity", d => +labelVisible(d.current))
    .attr("transform", d => labelTransform(d.current))
    .text(d => trim(d.data.name, 13, false));

  function trim(text, n, words) {
    if (text.length <= n) {
      return text;
    };
    const dots = "...";
    if (!words) {
      return text.slice(0, n) + dots;
    };
    const arr = text.split(" ");
    if (arr.length > 1) {
      if ((arr[0].length + arr[1].length) > n) {
        return arr[0] + dots;
      }
      return arr[0] + " " + arr[1] + dots;
    };
    return arr[0];
  }

  const parent = g.append("circle")
    .datum(root)
    .style("cursor", "pointer")
    .attr("r", radius)
    .attr("fill", "#bdbdbd")
    .attr("pointer-events", "all")
    .on("click", clicked);

  function clicked(event, p) {
    parent.datum(p.parent || root);

    root.each(d => d.target = {
      x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
      x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
      y0: Math.max(0, d.y0 - p.depth),
      y1: Math.max(0, d.y1 - p.depth)
    });

    const t = g.transition().duration(500);

    // Transition the data on all arcs, even the ones that aren’t visible,
    // so that if this transition is interrupted, entering arcs will start
    // the next transition from the desired position.
    path.transition(t)
      .tween("data", d => {
        const i = d3.interpolate(d.current, d.target);
        return t => d.current = i(t);
      })
      .filter(function (d) {
        return +this.getAttribute("fill-opacity") || arcVisible(d.target);
      })
      .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.8 : 0.4) : 0)
      .attr("pointer-events", d => arcVisible(d.target) ? "auto" : "none")

      .attrTween("d", d => () => arc(d.current));

    label.filter(function (d) {
      return +this.getAttribute("fill-opacity") || labelVisible(d.target);
    }).transition(t)
      .attr("fill-opacity", d => +labelVisible(d.target))
      .attrTween("transform", d => () => labelTransform(d.current));
  }

  function arcVisible(d) {
    return d.y1 <= 4 && d.y0 >= 1 && d.x1 > d.x0;
  }

  function labelVisible(d) {
    return d.y1 <= 4 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
  }


  function labelTransform(d) {
    const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
    const y = (d.y0 + d.y1) / 2 * radius;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  }

  return svg.node();
}

function _partition(d3) {
  return (
    data => {
      const root = d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value);
      return d3.partition()
        .size([2 * Math.PI, root.height + 1])
        (root);
    }
  )
}

function _color(d3, data) {
  return (
    d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1))
  )
}

function _format(d3) {
  return (
    d3.format(",d")
  )
}

function _width() {
  return (
    932
  )
}

function _radius(width) {
  return (
    width / 8
  )
}

function _arc(d3, radius) {
  return (
    d3.arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius(d => d.y0 * radius)
      .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1))
  )
}

export default async function define(runtime, observer) {
  const main = runtime.module();

  main.variable(observer("chart")).define("chart", ["partition", "data", "d3", "width", "color", "arc", "format", "radius"], _chart);
  main.variable(observer("data")).define("data", [], data);
  main.variable(observer("partition")).define("partition", ["d3"], _partition);
  main.variable(observer("color")).define("color", ["d3", "data"], _color);
  main.variable(observer("format")).define("format", ["d3"], _format);
  main.variable(observer("width")).define("width", _width);
  main.variable(observer("radius")).define("radius", ["width"], _radius);
  main.variable(observer("arc")).define("arc", ["d3", "radius"], _arc);
  return main;
}
