import React from 'react';
import Logo from './assets/images/logo.png';
import LocusImage from './assets/images/locus-1024x531-1.png';


export default function Page({ children }) {

    return (
        <>
            <div style={{ padding: "10px 0px 120px 40px" }}>
                <img src={Logo} width={220} alt="ClinicalSquared" style={{ float: "left", marginRight: 100 }} />
            </div>
            <div>
                <h1 style={{ paddingBottom: 50, color: "rgb(9, 115, 186, 1)" }}>Locus</h1>
            </div>
            <div>
                <img src={LocusImage} width={350} alt="Locus" style={{ float: "left", paddingLeft: 50 }} />
            </div>
            <div>
                <p style={{ padding: "10px 200px 60px 500px", textAlign: "left", lineHeight: 1.8 }}>

                    Locus™ collects and aggregates data from structured or unstructured datasets to provide the actionable information necessary for objective decision-making.

                    The Locus™ data navigation and orchestration tool enables users to view complex and dense data sets in an intuitive, animated format, allowing for multiple hierarchical tiers of information to be viewed at once in an uncluttered display. <strong>USPTO Patent Pending #63/063,087</strong>.
                    <ul><br />
                        <li>Keeps all filtered data vectors at your fingertips;</li>
                        <li>Dynamic interface is instinctive and easy to use;</li>
                        <li>Switch search parameters throughout your data tiers fluidly while maintaining filtering at the top hierarchies;</li>
                        <li>Gamified user experience simplifies data searches using multiple filters or in large data sets.</li>
                    </ul>
                    <br />
                    <p>
                        Locus™ promotes collaboration among decision makers resulting in actionable opportunities for improvement.
                    </p>
                    <p>
                        Drive improved results through the integration of information, comparative metrics, and best practices, targeting resources to their greatest effect.
                    </p>
                    <p>
                        The Locus™ works with existing analytics software, enabling your organization to navigate data effortlessly and without costly licensing.
                    </p>
                </p>

            </div>
            <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                {children}
            </div>
        </>
    );
}