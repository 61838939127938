const data = {
    "name": "state",
    "children": [
        {
            "name": "RI",
            "state npi count": 23276,
            "children": [
                {
                    "level": "grouping",
                    "name": "Agencies",
                    "grouping npi count": 564,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Case Management",
                            "classification npi count": 48
                        },
                        {
                            "level": "classification",
                            "name": "Community/Behavioral Health",
                            "classification npi count": 136
                        },
                        {
                            "level": "classification",
                            "name": "Day Training, Developmentally Disabled Services",
                            "classification npi count": 61
                        },
                        {
                            "level": "classification",
                            "name": "Early Intervention Provider Agency",
                            "classification npi count": 8
                        },
                        {
                            "level": "classification",
                            "name": "Foster Care Agency",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Home Health",
                            "classification npi count": 95
                        },
                        {
                            "level": "classification",
                            "name": "Home Infusion",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Hospice Care, Community Based",
                            "classification npi count": 11
                        },
                        {
                            "level": "classification",
                            "name": "In Home Supportive Care",
                            "classification npi count": 18
                        },
                        {
                            "level": "classification",
                            "name": "Local Education Agency (LEA)",
                            "classification npi count": 138
                        },
                        {
                            "level": "classification",
                            "name": "Nursing Care",
                            "classification npi count": 27
                        },
                        {
                            "level": "classification",
                            "name": "Public Health or Welfare",
                            "classification npi count": 11
                        },
                        {
                            "level": "classification",
                            "name": "Voluntary or Charitable",
                            "classification npi count": 6
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Hospitals",
                    "grouping npi count": 72,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Chronic Disease Hospital",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "General Acute Care Hospital",
                            "classification npi count": 50,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Critical Access",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rural",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Long Term Care Hospital",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Military Hospital",
                            "classification npi count": 3,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Military General Acute Care Hospital",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatric Hospital",
                            "classification npi count": 8
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Hospital",
                            "classification npi count": 5,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Children",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Special Hospital",
                            "classification npi count": 2
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Suppliers",
                    "grouping npi count": 577,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Department of Veterans Affairs (VA) Pharmacy",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Durable Medical Equipment & Medical Supplies",
                            "classification npi count": 323,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Customized Equipment",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nursing Facility Supplies",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oxygen Equipment & Supplies",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Parenteral & Enteral Nutrition",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Response System Companies",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Eyewear Supplier",
                            "classification npi count": 36
                        },
                        {
                            "level": "classification",
                            "name": "Hearing Aid Equipment",
                            "classification npi count": 10
                        },
                        {
                            "level": "classification",
                            "name": "Military/U.S. Coast Guard Pharmacy",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Non-Pharmacy Dispensing Site",
                            "classification npi count": 39
                        },
                        {
                            "level": "classification",
                            "name": "Pharmacy",
                            "classification npi count": 133,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinic Pharmacy",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community/Retail Pharmacy",
                                    "value": 68
                                },
                                {
                                    "level": "specialization",
                                    "name": "Compounding Pharmacy",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Home Infusion Therapy Pharmacy",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Institutional Pharmacy",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Long Term Care Pharmacy",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mail Order Pharmacy",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Portable X-ray and/or Other Portable Diagnostic Imaging Supplier",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Prosthetic/Orthotic Supplier",
                            "classification npi count": 27
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Laboratories",
                    "grouping npi count": 24,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Clinical Medical Laboratory",
                            "classification npi count": 21
                        },
                        {
                            "level": "classification",
                            "name": "Physiological Laboratory",
                            "classification npi count": 3
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Hospital Units",
                    "grouping npi count": 18,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Psychiatric Unit",
                            "classification npi count": 9
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Unit",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation, Substance Use Disorder Unit",
                            "classification npi count": 3
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Dental Providers",
                    "grouping npi count": 989,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Dental Hygienist",
                            "classification npi count": 10
                        },
                        {
                            "level": "classification",
                            "name": "Dental Laboratory Technician",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Dentist",
                            "classification npi count": 978,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Dental Public Health",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Endodontics",
                                    "value": 22
                                },
                                {
                                    "level": "specialization",
                                    "name": "General Practice",
                                    "value": 435
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oral and Maxillofacial Pathology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oral and Maxillofacial Surgery",
                                    "value": 44
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthodontics and Dentofacial Orthopedics",
                                    "value": 64
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Dentistry",
                                    "value": 39
                                },
                                {
                                    "level": "specialization",
                                    "name": "Periodontics",
                                    "value": 29
                                },
                                {
                                    "level": "specialization",
                                    "name": "Prosthodontics",
                                    "value": 13
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Student, Health Care",
                    "grouping npi count": 191,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Student in an Organized Health Care Education/Training Program",
                            "classification npi count": 191
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Respite Care Facility",
                    "grouping npi count": 6,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Respite Care",
                            "classification npi count": 6,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Respite Care, Mental Retardation and/or Developmental Disabilities",
                                    "value": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Chiropractic Providers",
                    "grouping npi count": 337,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Chiropractor",
                            "classification npi count": 337,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Independent Medical Examiner",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurology",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Occupational Health",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopedic",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Physician",
                                    "value": 4
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Other Service Providers",
                    "grouping npi count": 758,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Acupuncturist",
                            "classification npi count": 106
                        },
                        {
                            "level": "classification",
                            "name": "Case Manager/Care Coordinator",
                            "classification npi count": 136
                        },
                        {
                            "level": "classification",
                            "name": "Community Health Worker",
                            "classification npi count": 20
                        },
                        {
                            "level": "classification",
                            "name": "Contractor",
                            "classification npi count": 16,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Home Modifications",
                                    "value": 5
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Driver",
                            "classification npi count": 13
                        },
                        {
                            "level": "classification",
                            "name": "Genetic Counselor, MS",
                            "classification npi count": 14
                        },
                        {
                            "level": "classification",
                            "name": "Health Educator",
                            "classification npi count": 14
                        },
                        {
                            "level": "classification",
                            "name": "Interpreter",
                            "classification npi count": 27
                        },
                        {
                            "level": "classification",
                            "name": "Lactation Consultant, Non-RN",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Legal Medicine",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Mechanotherapist",
                            "classification npi count": 11
                        },
                        {
                            "level": "classification",
                            "name": "Medical Genetics, Ph.D. Medical Genetics",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Midwife",
                            "classification npi count": 22
                        },
                        {
                            "level": "classification",
                            "name": "Military Health Care Provider",
                            "classification npi count": 18,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Independent Duty Corpsman",
                                    "value": 17
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Naturopath",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Peer Specialist",
                            "classification npi count": 19
                        },
                        {
                            "level": "classification",
                            "name": "Reflexologist",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Specialist",
                            "classification npi count": 319,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Prosthetics Case Management",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Veterinarian",
                            "classification npi count": 8
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Transportation Services",
                    "grouping npi count": 134,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Ambulance",
                            "classification npi count": 85,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Land Transport",
                                    "value": 37
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Bus",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Non-emergency Medical Transport (VAN)",
                            "classification npi count": 42
                        },
                        {
                            "level": "classification",
                            "name": "Private Vehicle",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Secured Medical Transport (VAN)",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Taxi",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Transportation Broker",
                            "classification npi count": 1
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Nursing Service Providers",
                    "grouping npi count": 1012,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Licensed Practical Nurse",
                            "classification npi count": 37
                        },
                        {
                            "level": "classification",
                            "name": "Licensed Vocational Nurse",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Registered Nurse",
                            "classification npi count": 973,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction (Substance Use Disorder)",
                                    "value": 16
                                },
                                {
                                    "level": "specialization",
                                    "name": "Administrator",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Care",
                                    "value": 20
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cardiac Rehabilitation",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Case Management",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community Health",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Continuing Education/Staff Development",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Diabetes Educator",
                                    "value": 94
                                },
                                {
                                    "level": "specialization",
                                    "name": "Emergency",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gastroenterology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "General Practice",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hemodialysis",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Home Health",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Infection Control",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Infusion Therapy",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Lactation Consultant",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical-Surgical",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neonatal Intensive Care",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neuroscience",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Occupational Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopedic",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health",
                                    "value": 74
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Adult",
                                    "value": 37
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Child & Adolescent",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Registered Nurse First Assistant",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Women's Health Care, Ambulatory",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Wound Care",
                                    "value": 2
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Managed Care Organizations",
                    "grouping npi count": 27,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Exclusive Provider Organization",
                            "classification npi count": 11
                        },
                        {
                            "level": "classification",
                            "name": "Health Maintenance Organization",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Point of Service",
                            "classification npi count": 9
                        },
                        {
                            "level": "classification",
                            "name": "Preferred Provider Organization",
                            "classification npi count": 3
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Pharmacy Service Providers",
                    "grouping npi count": 639,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Pharmacist",
                            "classification npi count": 627,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Care",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatric",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist",
                                    "value": 21
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pharmacotherapy",
                                    "value": 25
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric",
                                    "value": 4
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pharmacy Technician",
                            "classification npi count": 12
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Residential Treatment Facilities",
                    "grouping npi count": 198,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Community Based Residential Treatment Facility, Mental Illness",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Community Based Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities",
                            "classification npi count": 125
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatric Residential Treatment Facility",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Residential Treatment Facility, Emotionally Disturbed Children",
                            "classification npi count": 19
                        },
                        {
                            "level": "classification",
                            "name": "Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities",
                            "classification npi count": 38
                        },
                        {
                            "level": "classification",
                            "name": "Residential Treatment Facility, Physical Disabilities",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Substance Abuse Rehabilitation Facility",
                            "classification npi count": 10,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Substance Abuse Treatment, Children",
                                    "value": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Ambulatory Health Care Facilities",
                    "grouping npi count": 488,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Clinic/Center",
                            "classification npi count": 488,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adolescent and Children Mental Health",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Day Care",
                                    "value": 29
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Mental Health",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Family Planning Facility",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Fertility Facility",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Surgical",
                                    "value": 20
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community Health",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Corporate Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Dental",
                                    "value": 45
                                },
                                {
                                    "level": "specialization",
                                    "name": "Emergency Care",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "End-Stage Renal Disease (ESRD) Treatment",
                                    "value": 19
                                },
                                {
                                    "level": "specialization",
                                    "name": "Endoscopy",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Family Planning, Non-Surgical",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Federally Qualified Health Center (FQHC)",
                                    "value": 23
                                },
                                {
                                    "level": "specialization",
                                    "name": "Health Service",
                                    "value": 16
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hearing and Speech",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Infusion Therapy",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Magnetic Resonance Imaging (MRI)",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical Specialty",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mental Health (Including Community Mental Health Center)",
                                    "value": 26
                                },
                                {
                                    "level": "specialization",
                                    "name": "Methadone",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Military Ambulatory Procedure Visits Operational (Transportable)",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Military/U.S. Coast Guard Outpatient",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Multi-Specialty",
                                    "value": 28
                                },
                                {
                                    "level": "specialization",
                                    "name": "Occupational Medicine",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oncology, Radiation",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ophthalmologic Surgery",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oral and Maxillofacial Surgery",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Physical Therapy",
                                    "value": 38
                                },
                                {
                                    "level": "specialization",
                                    "name": "Podiatric",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Primary Care",
                                    "value": 25
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiology",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiology, Mobile",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Recovery Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation",
                                    "value": 14
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation, Cardiac Facilities",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation, Comprehensive Outpatient Rehabilitation Facility (CORF)",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation, Substance Use Disorder",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sleep Disorder Diagnostic",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Student Health",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Urgent Care",
                                    "value": 24
                                },
                                {
                                    "level": "specialization",
                                    "name": "VA",
                                    "value": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Eye and Vision Services Providers",
                    "grouping npi count": 396,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Optometrist",
                            "classification npi count": 356,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Corneal and Contact Management",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Low Vision Rehabilitation",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Vision Therapy",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Technician/Technologist",
                            "classification npi count": 40,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Ocularist",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Optician",
                                    "value": 37
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Nursing Service Related Providers",
                    "grouping npi count": 31,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Adult Companion",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Chore Provider",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Doula",
                            "classification npi count": 13
                        },
                        {
                            "level": "classification",
                            "name": "Home Health Aide",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Homemaker",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Nurse's Aide",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Nursing Home Administrator",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Technician",
                            "classification npi count": 2,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Attendant Care Provider",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Personal Care Attendant",
                                    "value": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Allopathic & Osteopathic Physicians",
                    "grouping npi count": 6109,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Allergy & Immunology",
                            "classification npi count": 23,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Allergy",
                                    "value": 8
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Anesthesiology",
                            "classification npi count": 176,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain Medicine",
                                    "value": 10
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Clinical Pharmacology",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Colon & Rectal Surgery",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Dermatology",
                            "classification npi count": 125,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Dermatopathology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "MOHS-Micrographic Surgery",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Procedural Dermatology",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Electrodiagnostic Medicine",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Medicine",
                            "classification npi count": 417,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Emergency Medical Services",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Emergency Medicine",
                                    "value": 8
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Family Medicine",
                            "classification npi count": 519,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adolescent Medicine",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatric Medicine",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obesity Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sleep Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 7
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "General Practice",
                            "classification npi count": 28
                        },
                        {
                            "level": "classification",
                            "name": "Hospitalist",
                            "classification npi count": 15
                        },
                        {
                            "level": "classification",
                            "name": "Internal Medicine",
                            "classification npi count": 2014,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction Medicine",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adolescent Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cardiovascular Disease",
                                    "value": 145
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Cardiac Electrophysiology",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 40
                                },
                                {
                                    "level": "specialization",
                                    "name": "Endocrinology, Diabetes & Metabolism",
                                    "value": 38
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gastroenterology",
                                    "value": 106
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatric Medicine",
                                    "value": 25
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hematology",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hematology & Oncology",
                                    "value": 65
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hospice and Palliative Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Infectious Disease",
                                    "value": 48
                                },
                                {
                                    "level": "specialization",
                                    "name": "Interventional Cardiology",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Magnetic Resonance Imaging (MRI)",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical Oncology",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nephrology",
                                    "value": 37
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pulmonary Disease",
                                    "value": 52
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rheumatology",
                                    "value": 28
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sleep Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Medical Genetics",
                            "classification npi count": 2,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinical Biochemical Genetics",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Genetics (M.D.)",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Neurological Surgery",
                            "classification npi count": 47
                        },
                        {
                            "level": "classification",
                            "name": "Neuromusculoskeletal Medicine & OMM",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Neuromusculoskeletal Medicine, Sports Medicine",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Nuclear Medicine",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Obstetrics & Gynecology",
                            "classification npi count": 303,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Female Pelvic Medicine and Reconstructive Surgery",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gynecologic Oncology",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gynecology",
                                    "value": 23
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hospice and Palliative Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Maternal & Fetal Medicine",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obstetrics",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Reproductive Endocrinology",
                                    "value": 12
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Ophthalmology",
                            "classification npi count": 125,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Ophthalmic Plastic and Reconstructive Surgery",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Retina Specialist",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Oral & Maxillofacial Surgery",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Orthopaedic Surgery",
                            "classification npi count": 226,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adult Reconstructive Orthopaedic Surgery",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Foot and Ankle Surgery",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hand Surgery",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopaedic Surgery of the Spine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopaedic Trauma",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Orthopaedic Surgery",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 12
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Otolaryngology",
                            "classification npi count": 44,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Facial Plastic Surgery",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Otolaryngic Allergy",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Otolaryngology/Facial Plastic Surgery",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Otology & Neurotology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Otolaryngology",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pain Medicine",
                            "classification npi count": 12,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Interventional Pain Medicine",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain Medicine",
                                    "value": 4
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pathology",
                            "classification npi count": 146,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Anatomic Pathology",
                                    "value": 32
                                },
                                {
                                    "level": "specialization",
                                    "name": "Anatomic Pathology & Clinical Pathology",
                                    "value": 71
                                },
                                {
                                    "level": "specialization",
                                    "name": "Blood Banking & Transfusion Medicine",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Pathology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Pathology/Laboratory Medicine",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cytopathology",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Dermatopathology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Forensic Pathology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hematology",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical Microbiology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Molecular Genetic Pathology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neuropathology",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Pathology",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pediatrics",
                            "classification npi count": 583,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adolescent Medicine",
                                    "value": 28
                                },
                                {
                                    "level": "specialization",
                                    "name": "Developmental - Behavioral Pediatrics",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neonatal-Perinatal Medicine",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurodevelopmental Disabilities",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Allergy/Immunology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Cardiology",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Critical Care Medicine",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Emergency Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Endocrinology",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Gastroenterology",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Hematology-Oncology",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Infectious Diseases",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Nephrology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Pulmonology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Rheumatology",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Physical Medicine & Rehabilitation",
                            "classification npi count": 48,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Hospice and Palliative Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain Medicine",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 9
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Plastic Surgery",
                            "classification npi count": 44,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Plastic Surgery Within the Head and Neck",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgery of the Hand",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Preventive Medicine",
                            "classification npi count": 12,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Occupational Medicine",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Preventive Medicine/Occupational Environmental Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Public Health & General Preventive Medicine",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatry & Neurology",
                            "classification npi count": 566,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction Medicine",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Addiction Psychiatry",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Behavioral Neurology & Neuropsychiatry",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Child & Adolescent Psychiatry",
                                    "value": 47
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Neurophysiology",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Diagnostic Neuroimaging",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Forensic Psychiatry",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatric Psychiatry",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurocritical Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurology",
                                    "value": 109
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurology with Special Qualifications in Child Neurology",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatry",
                                    "value": 370
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychosomatic Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Vascular Neurology",
                                    "value": 4
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Radiology",
                            "classification npi count": 259,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Body Imaging",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Diagnostic Radiology",
                                    "value": 194
                                },
                                {
                                    "level": "specialization",
                                    "name": "Diagnostic Ultrasound",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neuroradiology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nuclear Radiology",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiation Oncology",
                                    "value": 35
                                },
                                {
                                    "level": "specialization",
                                    "name": "Therapeutic Radiology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Vascular & Interventional Radiology",
                                    "value": 12
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Surgery",
                            "classification npi count": 253,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Surgery",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Plastic and Reconstructive Surgery",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgery of the Hand",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical Critical Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical Oncology",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Vascular Surgery",
                                    "value": 12
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Thoracic Surgery (Cardiothoracic Vascular Surgery)",
                            "classification npi count": 19
                        },
                        {
                            "level": "classification",
                            "name": "Transplant Surgery",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Urology",
                            "classification npi count": 78
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Emergency Medical Service Providers",
                    "grouping npi count": 12,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Emergency Medical Technician, Basic",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Medical Technician, Intermediate",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Medical Technician, Paramedic",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Personal Emergency Response Attendant",
                            "classification npi count": 2
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Nursing & Custodial Care Facilities",
                    "grouping npi count": 270,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Alzheimer Center (Dementia Center)",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Assisted Living Facility",
                            "classification npi count": 62,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Assisted Living, Behavioral Disturbances",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Assisted Living, Mental Illness",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Custodial Care Facility",
                            "classification npi count": 3,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adult Care Home",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Intermediate Care Facility, Mental Illness",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Intermediate Care Facility, Mentally Retarded",
                            "classification npi count": 38
                        },
                        {
                            "level": "classification",
                            "name": "Nursing Facility/Intermediate Care Facility",
                            "classification npi count": 26
                        },
                        {
                            "level": "classification",
                            "name": "Skilled Nursing Facility",
                            "classification npi count": 136,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nursing Care, Pediatric",
                                    "value": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Dietary & Nutritional Service Providers",
                    "grouping npi count": 340,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Dietary Manager",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Dietitian, Registered",
                            "classification npi count": 309,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Metabolic",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Pediatric",
                                    "value": 24
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Renal",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Nutritionist",
                            "classification npi count": 30,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Education",
                                    "value": 15
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Behavioral Health & Social Service Providers",
                    "grouping npi count": 5192,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Assistant Behavior Analyst",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Behavior Analyst",
                            "classification npi count": 151
                        },
                        {
                            "level": "classification",
                            "name": "Behavior Technician",
                            "classification npi count": 212
                        },
                        {
                            "level": "classification",
                            "name": "Clinical Neuropsychologist",
                            "classification npi count": 70
                        },
                        {
                            "level": "classification",
                            "name": "Counselor",
                            "classification npi count": 1948,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction (Substance Use Disorder)",
                                    "value": 546
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mental Health",
                                    "value": 872
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pastoral",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Professional",
                                    "value": 34
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Marriage & Family Therapist",
                            "classification npi count": 126
                        },
                        {
                            "level": "classification",
                            "name": "Poetry Therapist",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Psychoanalyst",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Psychologist",
                            "classification npi count": 767,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction (Substance Use Disorder)",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical",
                                    "value": 389
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Child & Adolescent",
                                    "value": 47
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cognitive & Behavioral",
                                    "value": 16
                                },
                                {
                                    "level": "specialization",
                                    "name": "Counseling",
                                    "value": 22
                                },
                                {
                                    "level": "specialization",
                                    "name": "Exercise & Sports",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Family",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Forensic",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Group Psychotherapy",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Health Service",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mental Retardation & Developmental Disabilities",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Prescribing (Medical)",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychoanalysis",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 6
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Social Worker",
                            "classification npi count": 1911,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinical",
                                    "value": 1617
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Podiatric Medicine & Surgery Service Providers",
                    "grouping npi count": 159,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Podiatrist",
                            "classification npi count": 159,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Foot & Ankle Surgery",
                                    "value": 61
                                },
                                {
                                    "level": "specialization",
                                    "name": "Foot Surgery",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Primary Podiatric Medicine",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 2
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Speech, Language and Hearing Service Providers",
                    "grouping npi count": 574,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Audiologist",
                            "classification npi count": 75,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Assistive Technology Practitioner",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Audiologist-Hearing Aid Fitter",
                            "classification npi count": 12
                        },
                        {
                            "level": "classification",
                            "name": "Hearing Instrument Specialist",
                            "classification npi count": 25
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist",
                            "classification npi count": 5,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Speech-Language Assistant",
                                    "value": 4
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Speech-Language Pathologist",
                            "classification npi count": 457
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Physician Assistants & Advanced Practice Nursing Providers",
                    "grouping npi count": 2045,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Advanced Practice Midwife",
                            "classification npi count": 55
                        },
                        {
                            "level": "classification",
                            "name": "Anesthesiologist Assistant",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Clinical Nurse Specialist",
                            "classification npi count": 62,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adult Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Family Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neuroscience",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oncology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health",
                                    "value": 17
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Adult",
                                    "value": 19
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Child & Adolescent",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Community",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Women's Health",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Nurse Anesthetist, Certified Registered",
                            "classification npi count": 174
                        },
                        {
                            "level": "classification",
                            "name": "Nurse Practitioner",
                            "classification npi count": 1176,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Acute Care",
                                    "value": 71
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Health",
                                    "value": 127
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Family",
                                    "value": 458
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gerontology",
                                    "value": 25
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neonatal",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neonatal, Critical Care",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obstetrics & Gynecology",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 64
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics, Critical Care",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Primary Care",
                                    "value": 21
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health",
                                    "value": 64
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Women's Health",
                                    "value": 22
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Physician Assistant",
                            "classification npi count": 577,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Medical",
                                    "value": 114
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical",
                                    "value": 50
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Technologists, Technicians & Other Technical Service Providers",
                    "grouping npi count": 35,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Pathology",
                            "classification npi count": 1,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinical Laboratory Director, Non-physician",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Radiologic Technologist",
                            "classification npi count": 5,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Magnetic Resonance Imaging",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sonography",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Radiology Practitioner Assistant",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist Cardiovascular",
                            "classification npi count": 1,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Cardiovascular Invasive Specialist",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist, Other",
                            "classification npi count": 8,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Art, Medical",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nephrology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical Assistant",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist, Pathology",
                            "classification npi count": 7,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Chemistry",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hematology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Immunology",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Cardiology",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Health Information",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Other",
                            "classification npi count": 5,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "EEG",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Pathology",
                            "classification npi count": 2,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Phlebotomy",
                                    "value": 2
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Respiratory, Developmental, Rehabilitative and Restorative Service Providers",
                    "grouping npi count": 2079,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Dance Therapist",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Developmental Therapist",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Massage Therapist",
                            "classification npi count": 144
                        },
                        {
                            "level": "classification",
                            "name": "Music Therapist",
                            "classification npi count": 16
                        },
                        {
                            "level": "classification",
                            "name": "Occupational Therapist",
                            "classification npi count": 383,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Environmental Modification",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hand",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Low Vision",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 29
                                },
                                {
                                    "level": "specialization",
                                    "name": "Physical Rehabilitation",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Occupational Therapy Assistant",
                            "classification npi count": 119
                        },
                        {
                            "level": "classification",
                            "name": "Orthotic Fitter",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Orthotist",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Pedorthist",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Physical Therapist",
                            "classification npi count": 1128,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Cardiopulmonary",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Electrophysiology, Clinical",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatrics",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hand",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopedic",
                                    "value": 18
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports",
                                    "value": 5
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Physical Therapy Assistant",
                            "classification npi count": 137
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Counselor",
                            "classification npi count": 5,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Orientation and Mobility Training Provider",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Practitioner",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Respiratory Therapist, Certified",
                            "classification npi count": 12,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Critical Care",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Educational",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Respiratory Therapist, Registered",
                            "classification npi count": 6,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Critical Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Educational",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist",
                            "classification npi count": 113,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Athletic Trainer",
                                    "value": 112
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "name": "ID",
            "state npi count": 30359,
            "children": [
                {
                    "level": "grouping",
                    "name": "Agencies",
                    "grouping npi count": 1552,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Case Management",
                            "classification npi count": 290
                        },
                        {
                            "level": "classification",
                            "name": "Community/Behavioral Health",
                            "classification npi count": 489
                        },
                        {
                            "level": "classification",
                            "name": "Day Training, Developmentally Disabled Services",
                            "classification npi count": 121
                        },
                        {
                            "level": "classification",
                            "name": "Early Intervention Provider Agency",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Foster Care Agency",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Home Health",
                            "classification npi count": 192
                        },
                        {
                            "level": "classification",
                            "name": "Home Infusion",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Hospice Care, Community Based",
                            "classification npi count": 106
                        },
                        {
                            "level": "classification",
                            "name": "In Home Supportive Care",
                            "classification npi count": 110
                        },
                        {
                            "level": "classification",
                            "name": "Local Education Agency (LEA)",
                            "classification npi count": 165
                        },
                        {
                            "level": "classification",
                            "name": "Nursing Care",
                            "classification npi count": 44
                        },
                        {
                            "level": "classification",
                            "name": "Public Health or Welfare",
                            "classification npi count": 21
                        },
                        {
                            "level": "classification",
                            "name": "Supports Brokerage",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Voluntary or Charitable",
                            "classification npi count": 3
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Hospitals",
                    "grouping npi count": 153,
                    "children": [
                        {
                            "level": "classification",
                            "name": "General Acute Care Hospital",
                            "classification npi count": 119,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Children",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Access",
                                    "value": 53
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rural",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Long Term Care Hospital",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Military Hospital",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatric Hospital",
                            "classification npi count": 17
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Hospital",
                            "classification npi count": 8,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Children",
                                    "value": 5
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Religious Nonmedical Health Care Institution",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Special Hospital",
                            "classification npi count": 3
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Suppliers",
                    "grouping npi count": 926,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Department of Veterans Affairs (VA) Pharmacy",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Durable Medical Equipment & Medical Supplies",
                            "classification npi count": 485,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Customized Equipment",
                                    "value": 40
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nursing Facility Supplies",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oxygen Equipment & Supplies",
                                    "value": 43
                                },
                                {
                                    "level": "specialization",
                                    "name": "Parenteral & Enteral Nutrition",
                                    "value": 8
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Response System Companies",
                            "classification npi count": 8
                        },
                        {
                            "level": "classification",
                            "name": "Eyewear Supplier",
                            "classification npi count": 46
                        },
                        {
                            "level": "classification",
                            "name": "Hearing Aid Equipment",
                            "classification npi count": 18
                        },
                        {
                            "level": "classification",
                            "name": "Home Delivered Meals",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Indian Health Service/Tribal/Urban Indian Health (I/T/U) Pharmacy",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Military/U.S. Coast Guard Pharmacy",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Non-Pharmacy Dispensing Site",
                            "classification npi count": 11
                        },
                        {
                            "level": "classification",
                            "name": "Pharmacy",
                            "classification npi count": 304,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinic Pharmacy",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community/Retail Pharmacy",
                                    "value": 100
                                },
                                {
                                    "level": "specialization",
                                    "name": "Compounding Pharmacy",
                                    "value": 16
                                },
                                {
                                    "level": "specialization",
                                    "name": "Home Infusion Therapy Pharmacy",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Institutional Pharmacy",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Long Term Care Pharmacy",
                                    "value": 29
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mail Order Pharmacy",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Specialty Pharmacy",
                                    "value": 7
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Portable X-ray and/or Other Portable Diagnostic Imaging Supplier",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Prosthetic/Orthotic Supplier",
                            "classification npi count": 41
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Laboratories",
                    "grouping npi count": 79,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Clinical Medical Laboratory",
                            "classification npi count": 71
                        },
                        {
                            "level": "classification",
                            "name": "Dental Laboratory",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Physiological Laboratory",
                            "classification npi count": 6
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Hospital Units",
                    "grouping npi count": 35,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Medicare Defined Swing Bed Unit",
                            "classification npi count": 18
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatric Unit",
                            "classification npi count": 9
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Unit",
                            "classification npi count": 8
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Dental Providers",
                    "grouping npi count": 1887,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Dental Assistant",
                            "classification npi count": 8
                        },
                        {
                            "level": "classification",
                            "name": "Dental Hygienist",
                            "classification npi count": 39
                        },
                        {
                            "level": "classification",
                            "name": "Dentist",
                            "classification npi count": 1805,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Dental Public Health",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Dentist Anesthesiologist",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Endodontics",
                                    "value": 39
                                },
                                {
                                    "level": "specialization",
                                    "name": "General Practice",
                                    "value": 930
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oral and Maxillofacial Pathology",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oral and Maxillofacial Surgery",
                                    "value": 53
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthodontics and Dentofacial Orthopedics",
                                    "value": 106
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Dentistry",
                                    "value": 79
                                },
                                {
                                    "level": "specialization",
                                    "name": "Periodontics",
                                    "value": 23
                                },
                                {
                                    "level": "specialization",
                                    "name": "Prosthodontics",
                                    "value": 7
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Denturist",
                            "classification npi count": 35
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Student, Health Care",
                    "grouping npi count": 161,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Student in an Organized Health Care Education/Training Program",
                            "classification npi count": 161
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Respite Care Facility",
                    "grouping npi count": 8,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Respite Care",
                            "classification npi count": 8,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Respite Care, Mental Illness, Child",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Respite Care, Mental Retardation and/or Developmental Disabilities",
                                    "value": 4
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Chiropractic Providers",
                    "grouping npi count": 1090,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Chiropractor",
                            "classification npi count": 1090,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Independent Medical Examiner",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Internist",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurology",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition",
                                    "value": 14
                                },
                                {
                                    "level": "specialization",
                                    "name": "Occupational Health",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopedic",
                                    "value": 14
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Chiropractor",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation",
                                    "value": 14
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Physician",
                                    "value": 17
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Other Service Providers",
                    "grouping npi count": 891,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Acupuncturist",
                            "classification npi count": 107
                        },
                        {
                            "level": "classification",
                            "name": "Case Manager/Care Coordinator",
                            "classification npi count": 60
                        },
                        {
                            "level": "classification",
                            "name": "Community Health Worker",
                            "classification npi count": 33
                        },
                        {
                            "level": "classification",
                            "name": "Contractor",
                            "classification npi count": 17,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Home Modifications",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Driver",
                            "classification npi count": 9
                        },
                        {
                            "level": "classification",
                            "name": "Genetic Counselor, MS",
                            "classification npi count": 18
                        },
                        {
                            "level": "classification",
                            "name": "Health Educator",
                            "classification npi count": 38
                        },
                        {
                            "level": "classification",
                            "name": "Homeopath",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Interpreter",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Lactation Consultant, Non-RN",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Legal Medicine",
                            "classification npi count": 12
                        },
                        {
                            "level": "classification",
                            "name": "Lodging",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Meals",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Mechanotherapist",
                            "classification npi count": 18
                        },
                        {
                            "level": "classification",
                            "name": "Medical Genetics, Ph.D. Medical Genetics",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Midwife",
                            "classification npi count": 100
                        },
                        {
                            "level": "classification",
                            "name": "Midwife, Lay",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Military Health Care Provider",
                            "classification npi count": 23,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Independent Duty Medical Technicians",
                                    "value": 19
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Naturopath",
                            "classification npi count": 33
                        },
                        {
                            "level": "classification",
                            "name": "Peer Specialist",
                            "classification npi count": 33
                        },
                        {
                            "level": "classification",
                            "name": "Prevention Professional",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Reflexologist",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Specialist",
                            "classification npi count": 364,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Prosthetics Case Management",
                                    "value": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Transportation Services",
                    "grouping npi count": 213,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Ambulance",
                            "classification npi count": 109,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Air Transport",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Land Transport",
                                    "value": 70
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Bus",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Non-emergency Medical Transport (VAN)",
                            "classification npi count": 80
                        },
                        {
                            "level": "classification",
                            "name": "Private Vehicle",
                            "classification npi count": 13
                        },
                        {
                            "level": "classification",
                            "name": "Secured Medical Transport (VAN)",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Taxi",
                            "classification npi count": 8
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Nursing Service Providers",
                    "grouping npi count": 549,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Licensed Practical Nurse",
                            "classification npi count": 49
                        },
                        {
                            "level": "classification",
                            "name": "Licensed Vocational Nurse",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Registered Nurse",
                            "classification npi count": 497,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Administrator",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Care",
                                    "value": 14
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cardiac Rehabilitation",
                                    "value": 21
                                },
                                {
                                    "level": "specialization",
                                    "name": "Case Management",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community Health",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Diabetes Educator",
                                    "value": 28
                                },
                                {
                                    "level": "specialization",
                                    "name": "Emergency",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gastroenterology",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "General Practice",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gerontology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Home Health",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hospice",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Infusion Therapy",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Lactation Consultant",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical-Surgical",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neonatal Intensive Care",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neuroscience",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition Support",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obstetric, Inpatient",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ophthalmic",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopedic",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain Management",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Adult",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Child & Adolescent",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Registered Nurse First Assistant",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Women's Health Care, Ambulatory",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Wound Care",
                                    "value": 2
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Managed Care Organizations",
                    "grouping npi count": 46,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Exclusive Provider Organization",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Health Maintenance Organization",
                            "classification npi count": 12
                        },
                        {
                            "level": "classification",
                            "name": "Point of Service",
                            "classification npi count": 12
                        },
                        {
                            "level": "classification",
                            "name": "Preferred Provider Organization",
                            "classification npi count": 16
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Pharmacy Service Providers",
                    "grouping npi count": 1547,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Pharmacist",
                            "classification npi count": 1500,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Care",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Care",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatric",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nuclear",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition Support",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oncology",
                                    "value": 18
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist",
                                    "value": 41
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pharmacotherapy",
                                    "value": 54
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pharmacy Technician",
                            "classification npi count": 47
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Residential Treatment Facilities",
                    "grouping npi count": 82,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Community Based Residential Treatment Facility, Mental Illness",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Community Based Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities",
                            "classification npi count": 30
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatric Residential Treatment Facility",
                            "classification npi count": 9
                        },
                        {
                            "level": "classification",
                            "name": "Residential Treatment Facility, Emotionally Disturbed Children",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities",
                            "classification npi count": 11
                        },
                        {
                            "level": "classification",
                            "name": "Residential Treatment Facility, Physical Disabilities",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Substance Abuse Rehabilitation Facility",
                            "classification npi count": 19,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Substance Abuse Treatment, Children",
                                    "value": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Ambulatory Health Care Facilities",
                    "grouping npi count": 1215,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Clinic/Center",
                            "classification npi count": 1215,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adolescent and Children Mental Health",
                                    "value": 34
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Day Care",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Mental Health",
                                    "value": 63
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Family Planning Facility",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Surgical",
                                    "value": 71
                                },
                                {
                                    "level": "specialization",
                                    "name": "Birthing",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community Health",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Corporate Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Access Hospital",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Dental",
                                    "value": 136
                                },
                                {
                                    "level": "specialization",
                                    "name": "Developmental Disabilities",
                                    "value": 17
                                },
                                {
                                    "level": "specialization",
                                    "name": "Emergency Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "End-Stage Renal Disease (ESRD) Treatment",
                                    "value": 44
                                },
                                {
                                    "level": "specialization",
                                    "name": "Endoscopy",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Federally Qualified Health Center (FQHC)",
                                    "value": 61
                                },
                                {
                                    "level": "specialization",
                                    "name": "Health Service",
                                    "value": 31
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hearing and Speech",
                                    "value": 16
                                },
                                {
                                    "level": "specialization",
                                    "name": "Magnetic Resonance Imaging (MRI)",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical Specialty",
                                    "value": 29
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medically Fragile Infants and Children Day Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mental Health (Including Community Mental Health Center)",
                                    "value": 156
                                },
                                {
                                    "level": "specialization",
                                    "name": "Methadone",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Military/U.S. Coast Guard Outpatient",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Multi-Specialty",
                                    "value": 34
                                },
                                {
                                    "level": "specialization",
                                    "name": "Occupational Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oncology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oncology, Radiation",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ophthalmologic Surgery",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oral and Maxillofacial Surgery",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Physical Therapy",
                                    "value": 83
                                },
                                {
                                    "level": "specialization",
                                    "name": "Podiatric",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Primary Care",
                                    "value": 91
                                },
                                {
                                    "level": "specialization",
                                    "name": "Public Health, Federal",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiology",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiology, Mammography",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiology, Mobile",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation",
                                    "value": 26
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation, Comprehensive Outpatient Rehabilitation Facility (CORF)",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation, Substance Use Disorder",
                                    "value": 18
                                },
                                {
                                    "level": "specialization",
                                    "name": "Research",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rural Health",
                                    "value": 38
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sleep Disorder Diagnostic",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Student Health",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Urgent Care",
                                    "value": 23
                                },
                                {
                                    "level": "specialization",
                                    "name": "VA",
                                    "value": 12
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Eye and Vision Services Providers",
                    "grouping npi count": 594,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Optometrist",
                            "classification npi count": 538,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Corneal and Contact Management",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Low Vision Rehabilitation",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Vision",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Vision Therapy",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Technician/Technologist",
                            "classification npi count": 56,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Contact Lens",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Optician",
                                    "value": 51
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthoptist",
                                    "value": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Nursing Service Related Providers",
                    "grouping npi count": 83,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Adult Companion",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Chore Provider",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Day Training/Habilitation Specialist",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Doula",
                            "classification npi count": 9
                        },
                        {
                            "level": "classification",
                            "name": "Home Health Aide",
                            "classification npi count": 25
                        },
                        {
                            "level": "classification",
                            "name": "Homemaker",
                            "classification npi count": 8
                        },
                        {
                            "level": "classification",
                            "name": "Nurse's Aide",
                            "classification npi count": 9
                        },
                        {
                            "level": "classification",
                            "name": "Technician",
                            "classification npi count": 17,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Attendant Care Provider",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Personal Care Attendant",
                                    "value": 5
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Allopathic & Osteopathic Physicians",
                    "grouping npi count": 5212,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Allergy & Immunology",
                            "classification npi count": 22,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Allergy",
                                    "value": 13
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Anesthesiology",
                            "classification npi count": 178,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hospice and Palliative Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain Medicine",
                                    "value": 19
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Anesthesiology",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Clinical Pharmacology",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Colon & Rectal Surgery",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Dermatology",
                            "classification npi count": 99,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "MOHS-Micrographic Surgery",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Procedural Dermatology",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Electrodiagnostic Medicine",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Medicine",
                            "classification npi count": 315,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Emergency Medical Services",
                                    "value": 18
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Undersea and Hyperbaric Medicine",
                                    "value": 9
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Family Medicine",
                            "classification npi count": 1388,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adolescent Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Medicine",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatric Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hospice and Palliative Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sleep Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 17
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "General Practice",
                            "classification npi count": 55
                        },
                        {
                            "level": "classification",
                            "name": "Hospitalist",
                            "classification npi count": 34
                        },
                        {
                            "level": "classification",
                            "name": "Internal Medicine",
                            "classification npi count": 903,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adolescent Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Allergy & Immunology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cardiovascular Disease",
                                    "value": 105
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Cardiac Electrophysiology",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 23
                                },
                                {
                                    "level": "specialization",
                                    "name": "Endocrinology, Diabetes & Metabolism",
                                    "value": 21
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gastroenterology",
                                    "value": 91
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatric Medicine",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hematology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hematology & Oncology",
                                    "value": 41
                                },
                                {
                                    "level": "specialization",
                                    "name": "Infectious Disease",
                                    "value": 16
                                },
                                {
                                    "level": "specialization",
                                    "name": "Interventional Cardiology",
                                    "value": 17
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical Oncology",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nephrology",
                                    "value": 34
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obesity Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pulmonary Disease",
                                    "value": 39
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rheumatology",
                                    "value": 24
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sleep Medicine",
                                    "value": 4
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Medical Genetics",
                            "classification npi count": 3,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinical Genetics (M.D.)",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Neurological Surgery",
                            "classification npi count": 58
                        },
                        {
                            "level": "classification",
                            "name": "Neuromusculoskeletal Medicine & OMM",
                            "classification npi count": 7
                        },
                        {
                            "level": "classification",
                            "name": "Neuromusculoskeletal Medicine, Sports Medicine",
                            "classification npi count": 7
                        },
                        {
                            "level": "classification",
                            "name": "Nuclear Medicine",
                            "classification npi count": 2,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nuclear Cardiology",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Obstetrics & Gynecology",
                            "classification npi count": 247,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Gynecologic Oncology",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gynecology",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hospice and Palliative Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Maternal & Fetal Medicine",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obesity Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obstetrics",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Reproductive Endocrinology",
                                    "value": 4
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Ophthalmology",
                            "classification npi count": 111,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Ophthalmology and Strabismus Specialist",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Retina Specialist",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Oral & Maxillofacial Surgery",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Orthopaedic Surgery",
                            "classification npi count": 290,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adult Reconstructive Orthopaedic Surgery",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Foot and Ankle Surgery",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hand Surgery",
                                    "value": 20
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopaedic Surgery of the Spine",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopaedic Trauma",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 24
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Otolaryngology",
                            "classification npi count": 60,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Facial Plastic Surgery",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Otolaryngology/Facial Plastic Surgery",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Otology & Neurotology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Otolaryngology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Plastic Surgery within the Head & Neck",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pain Medicine",
                            "classification npi count": 27,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Interventional Pain Medicine",
                                    "value": 17
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain Medicine",
                                    "value": 10
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pathology",
                            "classification npi count": 52,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Anatomic Pathology",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Anatomic Pathology & Clinical Pathology",
                                    "value": 33
                                },
                                {
                                    "level": "specialization",
                                    "name": "Blood Banking & Transfusion Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Pathology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Pathology/Laboratory Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cytopathology",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Dermatopathology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hematology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neuropathology",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pediatrics",
                            "classification npi count": 284,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adolescent Medicine",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Developmental - Behavioral Pediatrics",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neonatal-Perinatal Medicine",
                                    "value": 26
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurodevelopmental Disabilities",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Cardiology",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Critical Care Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Emergency Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Endocrinology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Gastroenterology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Hematology-Oncology",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Infectious Diseases",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Nephrology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Pulmonology",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Phlebology",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Physical Medicine & Rehabilitation",
                            "classification npi count": 95,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Neuromuscular Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain Medicine",
                                    "value": 16
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Rehabilitation Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 7
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Plastic Surgery",
                            "classification npi count": 24,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Plastic Surgery Within the Head and Neck",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgery of the Hand",
                                    "value": 4
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Preventive Medicine",
                            "classification npi count": 30,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Aerospace Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Occupational Medicine",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Preventive Medicine/Occupational Environmental Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Public Health & General Preventive Medicine",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Undersea and Hyperbaric Medicine",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatry & Neurology",
                            "classification npi count": 276,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction Medicine",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Child & Adolescent Psychiatry",
                                    "value": 17
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Neurophysiology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Forensic Psychiatry",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatric Psychiatry",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurology",
                                    "value": 70
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurology with Special Qualifications in Child Neurology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neuromuscular Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatry",
                                    "value": 172
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychosomatic Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sleep Medicine",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Radiology",
                            "classification npi count": 290,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Body Imaging",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Diagnostic Radiology",
                                    "value": 227
                                },
                                {
                                    "level": "specialization",
                                    "name": "Diagnostic Ultrasound",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neuroradiology",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nuclear Radiology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiation Oncology",
                                    "value": 25
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiological Physics",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Therapeutic Radiology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Vascular & Interventional Radiology",
                                    "value": 10
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Surgery",
                            "classification npi count": 262,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Surgery",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Plastic and Reconstructive Surgery",
                                    "value": 19
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgery of the Hand",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical Critical Care",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical Oncology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Trauma Surgery",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Vascular Surgery",
                                    "value": 17
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Thoracic Surgery (Cardiothoracic Vascular Surgery)",
                            "classification npi count": 21
                        },
                        {
                            "level": "classification",
                            "name": "Urology",
                            "classification npi count": 64,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Female Pelvic Medicine and Reconstructive Surgery",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Urology",
                                    "value": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Emergency Medical Service Providers",
                    "grouping npi count": 13,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Emergency Medical Technician, Basic",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Medical Technician, Intermediate",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Medical Technician, Paramedic",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Personal Emergency Response Attendant",
                            "classification npi count": 2
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Nursing & Custodial Care Facilities",
                    "grouping npi count": 325,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Alzheimer Center (Dementia Center)",
                            "classification npi count": 7
                        },
                        {
                            "level": "classification",
                            "name": "Assisted Living Facility",
                            "classification npi count": 109,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Assisted Living, Behavioral Disturbances",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Assisted Living, Mental Illness",
                                    "value": 6
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Custodial Care Facility",
                            "classification npi count": 10,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adult Care Home",
                                    "value": 8
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Hospice, Inpatient",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Intermediate Care Facility, Mental Illness",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Intermediate Care Facility, Mentally Retarded",
                            "classification npi count": 41
                        },
                        {
                            "level": "classification",
                            "name": "Nursing Facility/Intermediate Care Facility",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Skilled Nursing Facility",
                            "classification npi count": 148,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nursing Care, Pediatric",
                                    "value": 2
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Dietary & Nutritional Service Providers",
                    "grouping npi count": 354,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Dietary Manager",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Dietitian, Registered",
                            "classification npi count": 340,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Metabolic",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Obesity and Weight Management",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Pediatric",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Renal",
                                    "value": 6
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Nutritionist",
                            "classification npi count": 13,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Education",
                                    "value": 6
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Behavioral Health & Social Service Providers",
                    "grouping npi count": 5718,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Assistant Behavior Analyst",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Behavior Analyst",
                            "classification npi count": 66
                        },
                        {
                            "level": "classification",
                            "name": "Behavior Technician",
                            "classification npi count": 133
                        },
                        {
                            "level": "classification",
                            "name": "Clinical Neuropsychologist",
                            "classification npi count": 34
                        },
                        {
                            "level": "classification",
                            "name": "Counselor",
                            "classification npi count": 2820,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction (Substance Use Disorder)",
                                    "value": 289
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mental Health",
                                    "value": 1249
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pastoral",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Professional",
                                    "value": 789
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 6
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Marriage & Family Therapist",
                            "classification npi count": 142
                        },
                        {
                            "level": "classification",
                            "name": "Psychologist",
                            "classification npi count": 381,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction (Substance Use Disorder)",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Development & Aging",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical",
                                    "value": 159
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Child & Adolescent",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cognitive & Behavioral",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Counseling",
                                    "value": 25
                                },
                                {
                                    "level": "specialization",
                                    "name": "Family",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Forensic",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Group Psychotherapy",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mental Retardation & Developmental Disabilities",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Prescribing (Medical)",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 10
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Social Worker",
                            "classification npi count": 2136,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinical",
                                    "value": 1048
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 5
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Podiatric Medicine & Surgery Service Providers",
                    "grouping npi count": 118,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Podiatrist",
                            "classification npi count": 118,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Foot & Ankle Surgery",
                                    "value": 42
                                },
                                {
                                    "level": "specialization",
                                    "name": "Foot Surgery",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Primary Podiatric Medicine",
                                    "value": 12
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Speech, Language and Hearing Service Providers",
                    "grouping npi count": 971,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Audiologist",
                            "classification npi count": 120,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Assistive Technology Supplier",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Audiologist-Hearing Aid Fitter",
                            "classification npi count": 22
                        },
                        {
                            "level": "classification",
                            "name": "Hearing Instrument Specialist",
                            "classification npi count": 69
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist",
                            "classification npi count": 10,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Audiology Assistant",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Speech-Language Assistant",
                                    "value": 6
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Speech-Language Pathologist",
                            "classification npi count": 750
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Physician Assistants & Advanced Practice Nursing Providers",
                    "grouping npi count": 3098,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Advanced Practice Midwife",
                            "classification npi count": 50
                        },
                        {
                            "level": "classification",
                            "name": "Clinical Nurse Specialist",
                            "classification npi count": 33,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adult Health",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Family Health",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Home Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oncology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Adult",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Child & Adolescent",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Chronically Ill",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Women's Health",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Nurse Anesthetist, Certified Registered",
                            "classification npi count": 399
                        },
                        {
                            "level": "classification",
                            "name": "Nurse Practitioner",
                            "classification npi count": 1467,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Acute Care",
                                    "value": 23
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Health",
                                    "value": 40
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community Health",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Family",
                                    "value": 739
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gerontology",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neonatal",
                                    "value": 14
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neonatal, Critical Care",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obstetrics & Gynecology",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Occupational Health",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 31
                                },
                                {
                                    "level": "specialization",
                                    "name": "Primary Care",
                                    "value": 36
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health",
                                    "value": 100
                                },
                                {
                                    "level": "specialization",
                                    "name": "Women's Health",
                                    "value": 33
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Physician Assistant",
                            "classification npi count": 1149,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Medical",
                                    "value": 311
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical",
                                    "value": 57
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Technologists, Technicians & Other Technical Service Providers",
                    "grouping npi count": 142,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Pathology",
                            "classification npi count": 1,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinical Laboratory Director, Non-physician",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Perfusionist",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Radiologic Technologist",
                            "classification npi count": 29,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Bone Densitometry",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Computed Tomography",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Magnetic Resonance Imaging",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiation Therapy",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiography",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sonography",
                                    "value": 10
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist Cardiovascular",
                            "classification npi count": 7,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Sonography",
                                    "value": 5
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist, Health Information",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist, Other",
                            "classification npi count": 79,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Art, Medical",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "EEG",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Electroneurodiagnostic",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical Assistant",
                                    "value": 22
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical Technologist",
                                    "value": 40
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist, Pathology",
                            "classification npi count": 2,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Laboratory Management",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Cardiology",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Other",
                            "classification npi count": 13,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "EEG",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Pathology",
                            "classification npi count": 6,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Histology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical Laboratory",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Phlebotomy",
                                    "value": 2
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Respiratory, Developmental, Rehabilitative and Restorative Service Providers",
                    "grouping npi count": 3297,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Clinical Exercise Physiologist",
                            "classification npi count": 12
                        },
                        {
                            "level": "classification",
                            "name": "Developmental Therapist",
                            "classification npi count": 58
                        },
                        {
                            "level": "classification",
                            "name": "Massage Therapist",
                            "classification npi count": 466
                        },
                        {
                            "level": "classification",
                            "name": "Mastectomy Fitter",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Music Therapist",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Occupational Therapist",
                            "classification npi count": 547,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Driving and Community Mobility",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ergonomics",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Feeding, Eating & Swallowing",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gerontology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hand",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mental Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurorehabilitation",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 55
                                },
                                {
                                    "level": "specialization",
                                    "name": "Physical Rehabilitation",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Occupational Therapy Assistant",
                            "classification npi count": 119,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Feeding, Eating & Swallowing",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Orthotic Fitter",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Orthotist",
                            "classification npi count": 8
                        },
                        {
                            "level": "classification",
                            "name": "Physical Therapist",
                            "classification npi count": 1534,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Geriatrics",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hand",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurology",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopedic",
                                    "value": 60
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 26
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Physical Therapy Assistant",
                            "classification npi count": 191
                        },
                        {
                            "level": "classification",
                            "name": "Prosthetist",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Recreation Therapist",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Counselor",
                            "classification npi count": 9
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Practitioner",
                            "classification npi count": 19
                        },
                        {
                            "level": "classification",
                            "name": "Respiratory Therapist, Certified",
                            "classification npi count": 17,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Critical Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Home Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pulmonary Function Technologist",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pulmonary Rehabilitation",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Respiratory Therapist, Registered",
                            "classification npi count": 8,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Critical Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pulmonary Function Technologist",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pulmonary Rehabilitation",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist",
                            "classification npi count": 292,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Athletic Trainer",
                                    "value": 285
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "name": "WY",
            "state npi count": 13999,
            "children": [
                {
                    "level": "grouping",
                    "name": "Agencies",
                    "grouping npi count": 750,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Case Management",
                            "classification npi count": 232
                        },
                        {
                            "level": "classification",
                            "name": "Community/Behavioral Health",
                            "classification npi count": 108
                        },
                        {
                            "level": "classification",
                            "name": "Day Training, Developmentally Disabled Services",
                            "classification npi count": 230
                        },
                        {
                            "level": "classification",
                            "name": "Early Intervention Provider Agency",
                            "classification npi count": 7
                        },
                        {
                            "level": "classification",
                            "name": "Foster Care Agency",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Home Health",
                            "classification npi count": 82
                        },
                        {
                            "level": "classification",
                            "name": "Hospice Care, Community Based",
                            "classification npi count": 31
                        },
                        {
                            "level": "classification",
                            "name": "In Home Supportive Care",
                            "classification npi count": 17
                        },
                        {
                            "level": "classification",
                            "name": "Local Education Agency (LEA)",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Nursing Care",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Public Health or Welfare",
                            "classification npi count": 30
                        },
                        {
                            "level": "classification",
                            "name": "Voluntary or Charitable",
                            "classification npi count": 5
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Hospitals",
                    "grouping npi count": 55,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Chronic Disease Hospital",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "General Acute Care Hospital",
                            "classification npi count": 46,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Critical Access",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rural",
                                    "value": 13
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Long Term Care Hospital",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Military Hospital",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatric Hospital",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Hospital",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Special Hospital",
                            "classification npi count": 2
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Suppliers",
                    "grouping npi count": 386,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Department of Veterans Affairs (VA) Pharmacy",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Durable Medical Equipment & Medical Supplies",
                            "classification npi count": 221,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Customized Equipment",
                                    "value": 16
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nursing Facility Supplies",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oxygen Equipment & Supplies",
                                    "value": 17
                                },
                                {
                                    "level": "specialization",
                                    "name": "Parenteral & Enteral Nutrition",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Response System Companies",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Eyewear Supplier",
                            "classification npi count": 13
                        },
                        {
                            "level": "classification",
                            "name": "Hearing Aid Equipment",
                            "classification npi count": 13
                        },
                        {
                            "level": "classification",
                            "name": "Home Delivered Meals",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Indian Health Service/Tribal/Urban Indian Health (I/T/U) Pharmacy",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Military/U.S. Coast Guard Pharmacy",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Non-Pharmacy Dispensing Site",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Pharmacy",
                            "classification npi count": 117,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinic Pharmacy",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community/Retail Pharmacy",
                                    "value": 36
                                },
                                {
                                    "level": "specialization",
                                    "name": "Compounding Pharmacy",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Home Infusion Therapy Pharmacy",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Institutional Pharmacy",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Long Term Care Pharmacy",
                                    "value": 9
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Prosthetic/Orthotic Supplier",
                            "classification npi count": 8
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Laboratories",
                    "grouping npi count": 45,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Clinical Medical Laboratory",
                            "classification npi count": 42
                        },
                        {
                            "level": "classification",
                            "name": "Physiological Laboratory",
                            "classification npi count": 3
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Hospital Units",
                    "grouping npi count": 27,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Medicare Defined Swing Bed Unit",
                            "classification npi count": 19
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatric Unit",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Unit",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation, Substance Use Disorder Unit",
                            "classification npi count": 1
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Dental Providers",
                    "grouping npi count": 583,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Dental Hygienist",
                            "classification npi count": 12
                        },
                        {
                            "level": "classification",
                            "name": "Dentist",
                            "classification npi count": 570,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Dental Public Health",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Endodontics",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "General Practice",
                                    "value": 295
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oral and Maxillofacial Surgery",
                                    "value": 17
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthodontics and Dentofacial Orthopedics",
                                    "value": 36
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Dentistry",
                                    "value": 24
                                },
                                {
                                    "level": "specialization",
                                    "name": "Periodontics",
                                    "value": 6
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Denturist",
                            "classification npi count": 1
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Student, Health Care",
                    "grouping npi count": 56,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Student in an Organized Health Care Education/Training Program",
                            "classification npi count": 56
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Respite Care Facility",
                    "grouping npi count": 26,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Respite Care",
                            "classification npi count": 26,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Respite Care, Mental Illness, Child",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Respite Care, Mental Retardation and/or Developmental Disabilities",
                                    "value": 14
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Chiropractic Providers",
                    "grouping npi count": 263,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Chiropractor",
                            "classification npi count": 263,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Independent Medical Examiner",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopedic",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Chiropractor",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Physician",
                                    "value": 4
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Other Service Providers",
                    "grouping npi count": 2416,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Acupuncturist",
                            "classification npi count": 19
                        },
                        {
                            "level": "classification",
                            "name": "Case Manager/Care Coordinator",
                            "classification npi count": 938
                        },
                        {
                            "level": "classification",
                            "name": "Community Health Worker",
                            "classification npi count": 1239
                        },
                        {
                            "level": "classification",
                            "name": "Contractor",
                            "classification npi count": 17,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Home Modifications",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Driver",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Funeral Director",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Genetic Counselor, MS",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Health Educator",
                            "classification npi count": 7
                        },
                        {
                            "level": "classification",
                            "name": "Interpreter",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Lactation Consultant, Non-RN",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Legal Medicine",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Mechanotherapist",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Medical Genetics, Ph.D. Medical Genetics",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Midwife",
                            "classification npi count": 11
                        },
                        {
                            "level": "classification",
                            "name": "Military Health Care Provider",
                            "classification npi count": 5,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Independent Duty Corpsman",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Independent Duty Medical Technicians",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Naturopath",
                            "classification npi count": 7
                        },
                        {
                            "level": "classification",
                            "name": "Peer Specialist",
                            "classification npi count": 16
                        },
                        {
                            "level": "classification",
                            "name": "Prevention Professional",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Specialist",
                            "classification npi count": 120,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Prosthetics Case Management",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Veterinarian",
                            "classification npi count": 7
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Transportation Services",
                    "grouping npi count": 78,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Ambulance",
                            "classification npi count": 74,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Air Transport",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Land Transport",
                                    "value": 43
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Non-emergency Medical Transport (VAN)",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Private Vehicle",
                            "classification npi count": 2
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Nursing Service Providers",
                    "grouping npi count": 439,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Licensed Practical Nurse",
                            "classification npi count": 45
                        },
                        {
                            "level": "classification",
                            "name": "Registered Nurse",
                            "classification npi count": 394,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction (Substance Use Disorder)",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Administrator",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Care",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Case Management",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community Health",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Diabetes Educator",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Emergency",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "General Practice",
                                    "value": 19
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gerontology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Home Health",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hospice",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Maternal Newborn",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical-Surgical",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neuroscience",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopedic",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain Management",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Adult",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Registered Nurse First Assistant",
                                    "value": 21
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Women's Health Care, Ambulatory",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Wound Care",
                                    "value": 2
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Managed Care Organizations",
                    "grouping npi count": 13,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Exclusive Provider Organization",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Point of Service",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Preferred Provider Organization",
                            "classification npi count": 6
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Pharmacy Service Providers",
                    "grouping npi count": 596,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Pharmacist",
                            "classification npi count": 585,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Care",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatric",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition Support",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oncology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pharmacotherapy",
                                    "value": 9
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pharmacy Technician",
                            "classification npi count": 11
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Residential Treatment Facilities",
                    "grouping npi count": 38,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Community Based Residential Treatment Facility, Mental Illness",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Community Based Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatric Residential Treatment Facility",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Residential Treatment Facility, Emotionally Disturbed Children",
                            "classification npi count": 13
                        },
                        {
                            "level": "classification",
                            "name": "Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Residential Treatment Facility, Physical Disabilities",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Substance Abuse Rehabilitation Facility",
                            "classification npi count": 8,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Substance Abuse Treatment, Children",
                                    "value": 5
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Ambulatory Health Care Facilities",
                    "grouping npi count": 569,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Clinic/Center",
                            "classification npi count": 569,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adolescent and Children Mental Health",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Day Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Mental Health",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Family Planning Facility",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Fertility Facility",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ambulatory Surgical",
                                    "value": 29
                                },
                                {
                                    "level": "specialization",
                                    "name": "Birthing",
                                    "value": 11
                                },
                                {
                                    "level": "specialization",
                                    "name": "Community Health",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Dental",
                                    "value": 36
                                },
                                {
                                    "level": "specialization",
                                    "name": "Developmental Disabilities",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "End-Stage Renal Disease (ESRD) Treatment",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Family Planning, Non-Surgical",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Federally Qualified Health Center (FQHC)",
                                    "value": 21
                                },
                                {
                                    "level": "specialization",
                                    "name": "Health Service",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hearing and Speech",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Infusion Therapy",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Magnetic Resonance Imaging (MRI)",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical Specialty",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mental Health (Including Community Mental Health Center)",
                                    "value": 51
                                },
                                {
                                    "level": "specialization",
                                    "name": "Multi-Specialty",
                                    "value": 52
                                },
                                {
                                    "level": "specialization",
                                    "name": "Occupational Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Ophthalmologic Surgery",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Oral and Maxillofacial Surgery",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Physical Therapy",
                                    "value": 37
                                },
                                {
                                    "level": "specialization",
                                    "name": "Podiatric",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Primary Care",
                                    "value": 33
                                },
                                {
                                    "level": "specialization",
                                    "name": "Public Health, Federal",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiology",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiology, Mammography",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiology, Mobile",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation, Comprehensive Outpatient Rehabilitation Facility (CORF)",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rehabilitation, Substance Use Disorder",
                                    "value": 41
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rural Health",
                                    "value": 36
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sleep Disorder Diagnostic",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Student Health",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Urgent Care",
                                    "value": 22
                                },
                                {
                                    "level": "specialization",
                                    "name": "VA",
                                    "value": 15
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Eye and Vision Services Providers",
                    "grouping npi count": 255,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Optometrist",
                            "classification npi count": 236,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Corneal and Contact Management",
                                    "value": 8
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Technician/Technologist",
                            "classification npi count": 19,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Ophthalmic",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Optician",
                                    "value": 18
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Nursing Service Related Providers",
                    "grouping npi count": 37,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Adult Companion",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Chore Provider",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Day Training/Habilitation Specialist",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Doula",
                            "classification npi count": 9
                        },
                        {
                            "level": "classification",
                            "name": "Home Health Aide",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Homemaker",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Nurse's Aide",
                            "classification npi count": 9
                        },
                        {
                            "level": "classification",
                            "name": "Religious Nonmedical Nursing Personnel",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Religious Nonmedical Practitioner",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Technician",
                            "classification npi count": 5,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Attendant Care Provider",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Personal Care Attendant",
                                    "value": 4
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Allopathic & Osteopathic Physicians",
                    "grouping npi count": 1901,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Allergy & Immunology",
                            "classification npi count": 6,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Allergy",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Anesthesiology",
                            "classification npi count": 147,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain Medicine",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Anesthesiology",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Colon & Rectal Surgery",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Dermatology",
                            "classification npi count": 25,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Dermatopathology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "MOHS-Micrographic Surgery",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Dermatology",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Electrodiagnostic Medicine",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Medicine",
                            "classification npi count": 136,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Emergency Medical Services",
                                    "value": 20
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Family Medicine",
                            "classification npi count": 438,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Medicine",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obesity Medicine",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "General Practice",
                            "classification npi count": 30
                        },
                        {
                            "level": "classification",
                            "name": "Hospitalist",
                            "classification npi count": 13
                        },
                        {
                            "level": "classification",
                            "name": "Internal Medicine",
                            "classification npi count": 309,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adolescent Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Allergy & Immunology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cardiovascular Disease",
                                    "value": 40
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Cardiac Electrophysiology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Critical Care Medicine",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Endocrinology, Diabetes & Metabolism",
                                    "value": 5
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gastroenterology",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "Geriatric Medicine",
                                    "value": 8
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hematology & Oncology",
                                    "value": 18
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hospice and Palliative Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Infectious Disease",
                                    "value": 9
                                },
                                {
                                    "level": "specialization",
                                    "name": "Interventional Cardiology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical Oncology",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nephrology",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pulmonary Disease",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Rheumatology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sleep Medicine",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Medical Genetics",
                            "classification npi count": 1,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinical Genetics (M.D.)",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Neurological Surgery",
                            "classification npi count": 17
                        },
                        {
                            "level": "classification",
                            "name": "Neuromusculoskeletal Medicine & OMM",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Neuromusculoskeletal Medicine, Sports Medicine",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Nuclear Medicine",
                            "classification npi count": 2,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nuclear Imaging & Therapy",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Obstetrics & Gynecology",
                            "classification npi count": 106,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Gynecology",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Maternal & Fetal Medicine",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obstetrics",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Ophthalmology",
                            "classification npi count": 24
                        },
                        {
                            "level": "classification",
                            "name": "Oral & Maxillofacial Surgery",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Orthopaedic Surgery",
                            "classification npi count": 125,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adult Reconstructive Orthopaedic Surgery",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hand Surgery",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopaedic Surgery of the Spine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 5
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Otolaryngology",
                            "classification npi count": 32,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Facial Plastic Surgery",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Otolaryngic Allergy",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Otolaryngology/Facial Plastic Surgery",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Plastic Surgery within the Head & Neck",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pain Medicine",
                            "classification npi count": 11,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Interventional Pain Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pain Medicine",
                                    "value": 7
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pathology",
                            "classification npi count": 34,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Anatomic Pathology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Anatomic Pathology & Clinical Pathology",
                                    "value": 24
                                },
                                {
                                    "level": "specialization",
                                    "name": "Blood Banking & Transfusion Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Pathology/Laboratory Medicine",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cytopathology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Molecular Genetic Pathology",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Pediatrics",
                            "classification npi count": 97,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adolescent Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Developmental - Behavioral Pediatrics",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Critical Care Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Endocrinology",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Physical Medicine & Rehabilitation",
                            "classification npi count": 17,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Pain Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports Medicine",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Plastic Surgery",
                            "classification npi count": 5,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Surgery of the Hand",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Preventive Medicine",
                            "classification npi count": 10,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Aerospace Medicine",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obesity Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Preventive Medicine/Occupational Environmental Medicine",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Public Health & General Preventive Medicine",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Undersea and Hyperbaric Medicine",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Psychiatry & Neurology",
                            "classification npi count": 92,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction Psychiatry",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Child & Adolescent Psychiatry",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Neurophysiology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Forensic Psychiatry",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neurology",
                                    "value": 22
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatry",
                                    "value": 59
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sleep Medicine",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Radiology",
                            "classification npi count": 85,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Body Imaging",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Diagnostic Radiology",
                                    "value": 64
                                },
                                {
                                    "level": "specialization",
                                    "name": "Diagnostic Ultrasound",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neuroradiology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nuclear Radiology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiation Oncology",
                                    "value": 12
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Surgery",
                            "classification npi count": 96,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Plastic and Reconstructive Surgery",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical Critical Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Trauma Surgery",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Vascular Surgery",
                                    "value": 5
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Thoracic Surgery (Cardiothoracic Vascular Surgery)",
                            "classification npi count": 7
                        },
                        {
                            "level": "classification",
                            "name": "Urology",
                            "classification npi count": 30,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Pediatric Urology",
                                    "value": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Emergency Medical Service Providers",
                    "grouping npi count": 12,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Emergency Medical Technician, Intermediate",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Emergency Medical Technician, Paramedic",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Personal Emergency Response Attendant",
                            "classification npi count": 4
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Nursing & Custodial Care Facilities",
                    "grouping npi count": 98,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Alzheimer Center (Dementia Center)",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Assisted Living Facility",
                            "classification npi count": 32
                        },
                        {
                            "level": "classification",
                            "name": "Custodial Care Facility",
                            "classification npi count": 2,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adult Care Home",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Intermediate Care Facility, Mentally Retarded",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Nursing Facility/Intermediate Care Facility",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Skilled Nursing Facility",
                            "classification npi count": 55,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nursing Care, Pediatric",
                                    "value": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Dietary & Nutritional Service Providers",
                    "grouping npi count": 122,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Dietary Manager",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Dietetic Technician, Registered",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Dietitian, Registered",
                            "classification npi count": 112,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Pediatric",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Sports Dietetics",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Nutritionist",
                            "classification npi count": 8,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Nutrition, Education",
                                    "value": 4
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Behavioral Health & Social Service Providers",
                    "grouping npi count": 2578,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Assistant Behavior Analyst",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Behavior Analyst",
                            "classification npi count": 37
                        },
                        {
                            "level": "classification",
                            "name": "Behavior Technician",
                            "classification npi count": 113
                        },
                        {
                            "level": "classification",
                            "name": "Clinical Neuropsychologist",
                            "classification npi count": 6
                        },
                        {
                            "level": "classification",
                            "name": "Counselor",
                            "classification npi count": 1328,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Addiction (Substance Use Disorder)",
                                    "value": 174
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mental Health",
                                    "value": 259
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pastoral",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Professional",
                                    "value": 388
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Marriage & Family Therapist",
                            "classification npi count": 73
                        },
                        {
                            "level": "classification",
                            "name": "Psychologist",
                            "classification npi count": 250,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Adult Development & Aging",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical",
                                    "value": 118
                                },
                                {
                                    "level": "specialization",
                                    "name": "Clinical Child & Adolescent",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Cognitive & Behavioral",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Counseling",
                                    "value": 12
                                },
                                {
                                    "level": "specialization",
                                    "name": "Forensic",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Group Psychotherapy",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Health Service",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Mental Retardation & Developmental Disabilities",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Prescribing (Medical)",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 7
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Social Worker",
                            "classification npi count": 769,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinical",
                                    "value": 655
                                },
                                {
                                    "level": "specialization",
                                    "name": "School",
                                    "value": 5
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Podiatric Medicine & Surgery Service Providers",
                    "grouping npi count": 35,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Podiatrist",
                            "classification npi count": 35,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Foot & Ankle Surgery",
                                    "value": 14
                                },
                                {
                                    "level": "specialization",
                                    "name": "Foot Surgery",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Primary Podiatric Medicine",
                                    "value": 2
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Speech, Language and Hearing Service Providers",
                    "grouping npi count": 357,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Audiologist",
                            "classification npi count": 57,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Assistive Technology Practitioner",
                                    "value": 5
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Audiologist-Hearing Aid Fitter",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Hearing Instrument Specialist",
                            "classification npi count": 25
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist",
                            "classification npi count": 5,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Audiology Assistant",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Speech-Language Assistant",
                                    "value": 4
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Speech-Language Pathologist",
                            "classification npi count": 267
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Physician Assistants & Advanced Practice Nursing Providers",
                    "grouping npi count": 967,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Advanced Practice Midwife",
                            "classification npi count": 18
                        },
                        {
                            "level": "classification",
                            "name": "Anesthesiologist Assistant",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Clinical Nurse Specialist",
                            "classification npi count": 18,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Community Health/Public Health",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Family Health",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical-Surgical",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Adult",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health, Child & Adolescent",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Women's Health",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Nurse Anesthetist, Certified Registered",
                            "classification npi count": 85
                        },
                        {
                            "level": "classification",
                            "name": "Nurse Practitioner",
                            "classification npi count": 527,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Acute Care",
                                    "value": 10
                                },
                                {
                                    "level": "specialization",
                                    "name": "Adult Health",
                                    "value": 13
                                },
                                {
                                    "level": "specialization",
                                    "name": "Family",
                                    "value": 307
                                },
                                {
                                    "level": "specialization",
                                    "name": "Gerontology",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Neonatal",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Obstetrics & Gynecology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 15
                                },
                                {
                                    "level": "specialization",
                                    "name": "Primary Care",
                                    "value": 16
                                },
                                {
                                    "level": "specialization",
                                    "name": "Psychiatric/Mental Health",
                                    "value": 45
                                },
                                {
                                    "level": "specialization",
                                    "name": "Women's Health",
                                    "value": 12
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Physician Assistant",
                            "classification npi count": 318,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Medical",
                                    "value": 86
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical",
                                    "value": 17
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Technologists, Technicians & Other Technical Service Providers",
                    "grouping npi count": 65,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Pathology",
                            "classification npi count": 1,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Clinical Laboratory Director, Non-physician",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Radiologic Technologist",
                            "classification npi count": 19,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Magnetic Resonance Imaging",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Nuclear Medicine Technology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Radiography",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sonography",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Radiology Practitioner Assistant",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist, Other",
                            "classification npi count": 17,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Electroneurodiagnostic",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopedic Assistant",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical Assistant",
                                    "value": 6
                                },
                                {
                                    "level": "specialization",
                                    "name": "Surgical Technologist",
                                    "value": 5
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist, Pathology",
                            "classification npi count": 12,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Medical Technologist",
                                    "value": 12
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Cardiology",
                            "classification npi count": 3
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Health Information",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Other",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Technician, Pathology",
                            "classification npi count": 6,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Histology",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "Medical Laboratory",
                                    "value": 4
                                },
                                {
                                    "level": "specialization",
                                    "name": "Phlebotomy",
                                    "value": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "level": "grouping",
                    "name": "Respiratory, Developmental, Rehabilitative and Restorative Service Providers",
                    "grouping npi count": 1232,
                    "children": [
                        {
                            "level": "classification",
                            "name": "Dance Therapist",
                            "classification npi count": 2
                        },
                        {
                            "level": "classification",
                            "name": "Developmental Therapist",
                            "classification npi count": 12
                        },
                        {
                            "level": "classification",
                            "name": "Massage Therapist",
                            "classification npi count": 67
                        },
                        {
                            "level": "classification",
                            "name": "Music Therapist",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Occupational Therapist",
                            "classification npi count": 301,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Gerontology",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Hand",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 23
                                },
                                {
                                    "level": "specialization",
                                    "name": "Physical Rehabilitation",
                                    "value": 3
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Occupational Therapy Assistant",
                            "classification npi count": 60
                        },
                        {
                            "level": "classification",
                            "name": "Orthotist",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Physical Therapist",
                            "classification npi count": 607,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Geriatrics",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "Orthopedic",
                                    "value": 20
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pediatrics",
                                    "value": 7
                                },
                                {
                                    "level": "specialization",
                                    "name": "Sports",
                                    "value": 2
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Physical Therapy Assistant",
                            "classification npi count": 71
                        },
                        {
                            "level": "classification",
                            "name": "Prosthetist",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Pulmonary Function Technologist",
                            "classification npi count": 1
                        },
                        {
                            "level": "classification",
                            "name": "Recreation Therapist",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Counselor",
                            "classification npi count": 4
                        },
                        {
                            "level": "classification",
                            "name": "Rehabilitation Practitioner",
                            "classification npi count": 5
                        },
                        {
                            "level": "classification",
                            "name": "Respiratory Therapist, Certified",
                            "classification npi count": 9,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Critical Care",
                                    "value": 1
                                },
                                {
                                    "level": "specialization",
                                    "name": "General Care",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Respiratory Therapist, Registered",
                            "classification npi count": 25,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Critical Care",
                                    "value": 2
                                },
                                {
                                    "level": "specialization",
                                    "name": "General Care",
                                    "value": 3
                                },
                                {
                                    "level": "specialization",
                                    "name": "Pulmonary Function Technologist",
                                    "value": 1
                                }
                            ]
                        },
                        {
                            "level": "classification",
                            "name": "Specialist/Technologist",
                            "classification npi count": 55,
                            "children": [
                                {
                                    "level": "specialization",
                                    "name": "Athletic Trainer",
                                    "value": 55
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}

export default data;